<template>
	<div class="basic_info">
		<div class="title">安全信息</div>
		<div class="info_box">
			<van-cell-group>
			  <van-field label="手机号" v-model="info.phone"  placeholder="请输入手机号" :disabled="this.phone != '' && this.phone != 'null'" />
			</van-cell-group>
			
			<div v-if="false" class="text">图形验证码</div>
			<van-cell-group v-if="false" class="code">
			  <van-field
			    v-model="verifyCode"
			    placeholder="请输入验证码"
			  />
			  <img class="codeimg" style="width: 120px; height: 40px;" :src="verifyCodeImg" @click="handleRefresh()">
			  <van-field
			    v-model="verifyKey"
			  	style="display: none;"
			  />
			</van-cell-group>
			<div v-if="false" class="text">手机验证码</div>
			<van-cell-group v-if="false" class="code">
			  <van-field v-model="formData.sms" placeholder="请输入手机验证码"/>
			  <van-button class="codebtn" v-if="showbtn" color="#1989fa"  @click="handleSend()">获取验证码</van-button>
			  <van-button class="codebtn codebtn_second" v-else color="#1989fa" :disabled="attcode">{{code_ts}}</van-button>
			</van-cell-group>
			<van-cell-group v-if="false">
				<van-field v-model="info.payBank" label="开户行" center  placeholder="请输入开户行名称" :disabled="this.payBank != ''&& this.payBank != 'null'"></van-field>
			</van-cell-group>
			<van-cell-group v-if="false">
				<van-field v-model="info.payCardno" label="银行卡号" center  placeholder="银行卡卡号" :disabled="this.payCardno != '' && this.payCardno != 'null'"></van-field>
			</van-cell-group>
			<div class="text" v-if="false">收款码</div>
			<div class="Paycode" v-if="false">
				<div class="code_box">
					<img v-if="this.payWeixin !=''" width="88px" :src="'https://file.aleopool.top/' + this.payWeixin" alt="" @click ="showVX()">
					<van-uploader v-else v-model="fileList1" :max-count="1" :after-read="uploadVX" @delete="delimageVX" />
					<div class="code_text">微信</div>
				</div>
				<div class="code_box">
					<img v-if="this.payZhifubao !=''" width="80px" :src="'https://file.aleopool.top/' + this.payZhifubao" alt="" @click ="showZFB()">
					<van-uploader v-else v-model="fileList2" :max-count="1" :after-read="uploadZFB" @delete="delimageZFB" />
					<div class="code_text">支付宝</div>
				</div>
			</div>
			<van-cell-group>
				<van-field v-model="info.aleoAddress" label="aleo钱包地址" center  placeholder="请输入要绑定的钱包地址" :disabled="this.aleoAddress != '' && this.aleoAddress != 'null'"></van-field>
			</van-cell-group>
			<div class="btn">
				<van-button size="small" type="primary" v-clipboard:copy="info.aleoAddress" v-clipboard:success="onCopy" v-clipboard:error="onError" ><span>复制钱包地址</span></van-button>
				<van-button size="small" type="info" class="Safe_btn" @click="handleSafe()" v-if="bindbtn"><span>绑定</span></van-button>
			</div>
		</div>
			<van-popup v-model:show="vx">
				<img width="300px" :src="'https://file.aleopool.top/' + this.payWeixin" alt="">
			</van-popup>
			<van-popup v-model:show="zfb">
				<img width="300px" :src="'https://file.aleopool.top/' + this.payZhifubao" alt="">
			</van-popup>
			<!-- <div class="note">提示：提现时与手机绑在一起，如果想修改，请联系客服</div> -->
	</div>
</template>

<script>
	import { Personal,Admin,Buy } from "@/api/index";
	  export default {
	    data() {
	      return {
			attcode: false, //点击获取验证码按钮判断
			showbtn: true, // 展示获取验证码或倒计时按钮判断
			yzcode: '',
			code_ts: '获取验证码', //倒计时提示文字
			sec: 60, // 倒计时秒数
			//信息
			info:{},
			//图形验证码
			verifyCode:'',
			verifyKey:'',
			verifyCodeImg:'',
			//验证码表单
			formData:{
				sms:'',
				phone:'',
			},
			phone:'',
			payCardno:'',
			payBank:'',
			payWeixin:'',
			payZhifubao:'',
			aleoAddress:'',
			//收款图
			fileList1:[],
			fileList2:[],
			vx:false,
			zfb:false,
			bindbtn:true,
	      };
	    },
		created() {
			this.handleCaptcha()
			this.Info();
			if(this.phone !='' && this.payBank != '' && this.payCardno != '' && this.aleoAddress != '' && this.payWeixin != '' && this.payZhifubao != '' ) {
				this.bindbtn = false;
			}else {
				this.bindbtn = true;
			}
		},
		methods:{
			showVX() {
				this.vx = true;
			},
			showZFB() {
				this.zfb = true;
			},
			//个人信息
			async Info() {
				let { status, data } = await Personal.info();
				if (status) {
					if(data.code == 0) {
						this.info = data.data;
						this.phone = this.info.phone;
						this.payCardno = this.info.payCardno;
						this.payBank = this.info.payBank;
						this.payWeixin = this.info.payWeixin;
						this.payZhifubao = this.info.payZhifubao;
						this.aleoAddress = this.info.aleoAddress;
					}
				}
			},
			//手机验证码
			async handleSend() {
				if(this.verifyCode=='' && this.verifyKey ==''&& this.info.phone=='') {
					this.$message.error('请先填写手机号或图形验证码');
					return;
				}
				let {
					status,
					data
				} = await Admin.sendsms({
					Mobile:this.info.phone,
					VerifyCode:this.verifyCode,
					VerifyKey:this.verifyKey,
				});
				if (status) {
					if (data.code == 0) {
						var timer = setInterval(() => {
							this.sec = this.sec - 1
							this.code_ts = this.sec + 'S后重试'
							this.showbtn = false
							this.attcode = true
							if (this.sec === 0) {
								clearInterval(timer)
								this.sec = 60
								this.code_ts = this.sec + 'S后重试'
								this.showbtn = true
								this.attcode = false
							}
						}, 1000)
						this.$message.success('发送成功');
					} else {
						this.$message.error(data.message);
					}
				}
			},
			//绑定安全信息
			async handleSafe() {
				// if(this.verifyCode=='' && this.verifyKey ==''&& this.info.phone==''&& this.info.aleoAddress=='') {
				if(this.info.aleoAddress=='') {
					this.$message.error('请先填写以上信息');
					return;
				}
				let {
					status,
					data
				} = await Admin.security({
					phone:this.info.phone,
					sms:this.formData.sms,
					aleoAddress:this.info.aleoAddress,
					payWeixin:this.info.payWeixin,
					payZhifubao:this.info.payZhifubao,
					payCardno:this.info.payCardno,
					payBank:this.info.payBank,
				});
				if (status) {
					if (data.code == 0) {
						this.$message.success('操作成功');
						this.$router.go(0)
					} else {
						this.$message.error(data.message);
					}
				}
			},
			//获取验证码
			async handleCaptcha(values) {
				let { status, data } = await Admin.verifyCode();
				if (status) {
					if (data.code === 0) {
						this.verifyCodeImg = data.data.img
						this.verifyKey = data.data.key
					} 
				} else {
					this.$message.error("data.message")
				}
			},
			//点击图片重新获取验证码
			handleRefresh() {
				this.handleCaptcha();
			},
			onCopy(e){// 复制成功
				this.$message.success('复制成功')
			},
			onError(e){// 复制失败
				this.$message.success('复制失败')
			},
			//微信图片
			uploadVX(file) {
				file.status = 'uploading';
				file.message = '上传中...';
				let formData = new FormData();
				formData.append('file', file.file);
				Buy.ImageUpload(formData).then(res => {
					if(res.data.data.path != '') {
						file.status = 'success';
						file.message = '上传成功';
						this.info.payWeixin = res.data.data.path;
						this.$message.success('上传成功')
					}else {
						file.status = 'failed';
						file.message = '上传失败';
					}
				}).catch(err => {})
			},
			delimageVX() {
				this.fileList1.filter((item, indexs) => {
					if (index.index == indexs) {
						this.fileList1.splice(indexs, 1); //splice 删除
					}
				});
				return true;
			},
			//支付宝图片
			uploadZFB(file) {
				file.status = 'uploading';
				file.message = '上传中...';
				let formData = new FormData();
				formData.append('file', file.file);
				Buy.ImageUpload(formData).then(res => {
					if(res.data.data.path != '') {
						file.status = 'success';
						file.message = '上传成功';
						this.info.payZhifubao = res.data.data.path
						this.$message.success('上传成功')
					}else {
						file.status = 'failed';
						file.message = '上传失败';
					}
				}).catch(err => {})
			},
			delimageZFB() {
				this.fileList2.filter((item, indexs) => {
					if (index.index == indexs) {
						this.fileList2.splice(indexs, 1); //splice 删除
					}
				});
				return true;
			},
		},
		
	  }
</script>

<style lang="less">
	.basic_info {
		min-height: 100vh;
		margin-bottom: 15vw;
		.title {
			font-size: 3.5vw;
			margin: 5vw;
			color: #124DA5;
			font-weight: bold;
		}
		.info_box {
			margin: 0 5vw 5vw 5vw;
			padding: 5vw 5vw;
			box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
			border-radius: 5vw;
			.Paycode {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 3vw 5vw;
				.code_box {
					.van-uploader__upload {
						margin: 0 0 2vw 0;
					}
					.code_text {
						color: #333333;
						font-size: 3vw;
						text-align: center;
					}
				}
			}
			.code {
				display: flex;
				align-items: center;
				.codebtn {
					width: 40vw;
					height: 9vw;
					padding: 0px 1px;
				}
				.codebtn_second {
					opacity: 0.2;
				}
			}
			.text {
				color: #333333;
				font-size: 3.5vw;
				margin-left: 5vw;
			}
			.van-field--disabled .van-field__label {
				color: #333333;
			}
			.van-cell {
				display: block;
				font-size: 3.5vw;
				color: #333333;
			}
			.note {
				color: #999999;
				font-size: 3vw;
				margin-left: 5vw;
			}
			.van-button {
				border-radius: 2vw;
				margin-top: 2vw;
				
			}
			.van-field__label {
				width: 30vw;
			}
			.van-field__control {
				border: 0.2vw solid #eee;
				padding: 1vw 3vw;
				border-radius: 2vw;
				margin-top: 2vw;
			}
		}
		[class*=van-hairline]::after {
			width: 0;
		}
		.van-dialog {
			.dialog {
				margin: 0 5vw;
			}
			.cash_num {
				margin: 2vw 5vw 0;
				color: #124DA5;
				font-weight: bold;
			}
			.van-cell__value {
				border-bottom: 0.1vw solid #ccc;
			}
			.van-cell {
				font-size: 3.5vw;
			}
			.validation {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.van-button {
					width: 40vw;
					margin-right: 2vw;
					height: 10vw;
				}
			}
		}
		.van-cell:after {
			width: 0;
		}
		input{
		    -webkit-appearance: none;
		}
		.btn {
			padding: 10px 16px;
			display: flex;
			justify-content: space-between;
			.van-button {
				margin-right: 4vw;
			}
			.Safe_btn {
				width: 20vw;
			}
		}
	}
</style>
